<template>
  <Drawer :items="itemsFiltered" :logo-click="logoClick" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Drawer from '@/components/drawer/Drawer.vue';
import { SubscriptionModule } from '@/types';
import type { Location } from 'vue-router';
import type { UserResponse } from '@/types/schema';

type SubscriptionResponse = {
  period: string;
  price: number;
  validFrom: string;
  subscriptionPlan: {
    id: string;
    code: string;
    name: string;
    modules: SubscriptionModule[];
  };
};

type MenuItem = {
  text: string;
  icon?: string;
  color?: string;
  to?: string | Location;
  company?: boolean;
  modules?: SubscriptionModule[];
  systemRole?: UserResponse['systemRole'];
  exact?: boolean;
  items?: MenuItem[];
};

export default defineComponent({
  components: { Drawer },
  data: () => ({
    opened: true,
    loading: true,
  }),
  computed: {
    ...mapState('subscription', {
      currentSubscription: (state: any) => state.currentSubscription,
    }),
    ...mapGetters({
      advancedMode: 'theme/getAdvancedMode',
      selectedCompany: 'selectedCompany',
      companyIsOnboarded: 'bankIntegration/getCompanyOnboardingStatus',
    }),
    subscription() {
      return this.currentSubscription[this.selectedCompany.id] as SubscriptionResponse | undefined;
    },
    companies() {
      return this.$store.getters.companies as any[];
    },
    company() {
      return this.$store.getters.getCompanyById(this.companyId);
    },
    companyNameSlug() {
      return this.$store.getters.getCompanyNameSlugById(this.companyId);
    },
    user() {
      return this.$store.getters.getUser as UserResponse;
    },
    items(): MenuItem[] {
      if (this.advancedMode) {
        const menu: MenuItem[] = [
          {
            text: 'Oversikt',
            icon: 'mdi-home',
            to: { name: 'Dashboard', params: { companyNameSlug: this.companyNameSlug } },
            company: true,
            modules: [SubscriptionModule.INVOICE],
          },
          {
            text: 'Bokføring',
            icon: 'mdi-book-plus-outline',
            color: 'success',
            company: true,
            modules: [SubscriptionModule.ACCOUNTING],
            items: [
              {
                text: 'Bokfør godkjente filer',
                to: {
                  name: 'PurchaseBulkCreate',
                  params: { companyNameSlug: this.companyNameSlug },
                  query: {
                    // preserve query params if already on the page (skips navigation basically)
                    ...(this.$route.name === 'PurchaseBulkCreate' ? { ...this.$route.query } : {}),
                  },
                },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Bokfør andre filer',
                to: { name: 'PurchaseCreate', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Filmottak',
                to: {
                  name: 'FileInbox',
                  params: { companyNameSlug: this.companyNameSlug },
                },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Mva. rapport',
                to: { name: 'MVAReportIndex', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
            ],
          },
          {
            text: 'Bankavstemming',
            icon: 'mdi-human-male-board-poll',
            to: { name: 'BankReconciliationIndex', params: { companyNameSlug: this.companyNameSlug } },
            company: true,
            modules: [SubscriptionModule.ACCOUNTING],
          },
          {
            text: 'Faktura',
            icon: 'mdi-text-box-plus-outline',
            color: 'success',
            company: true,
            to: { name: 'Invoices', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.INVOICE],
          },
          {
            text: 'Kontakter',
            icon: 'mdi-account-multiple',
            color: 'amber',
            to: { name: 'Contacts', params: { companyNameSlug: this.companyNameSlug } },
            company: true,
            modules: [SubscriptionModule.INVOICE],
          },
          {
            text: 'Produkter',
            icon: 'mdi-cube',
            color: 'purple',
            company: true,
            to: { name: 'Products', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.INVOICE],
          },
          {
            text: 'Ansatte',
            icon: 'mdi-badge-account',
            color: 'amber',
            company: true,
            to: { name: 'Employees', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.SALARY],
          },
          {
            text: 'Timeføring',
            icon: 'mdi-clock-outline',
            company: true,
            to: { name: 'Timesheet', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.SALARY, SubscriptionModule.TIMESHEET],
          },
          {
            text: 'Lønn',
            icon: 'mdi-credit-card-outline',
            color: 'amber',
            company: true,
            to: { name: 'Salary', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.SALARY],
          },
          {
            text: 'Rapporter',
            icon: 'mdi-chart-scatter-plot-hexbin',
            color: 'success',
            company: true,
            modules: [SubscriptionModule.ACCOUNTING],
            items: [
              {
                text: 'Balanserapport',
                to: { name: 'BalanceSheetIndex', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Bilagsjournal',
                to: { name: 'Vouchers', params: { companyNameSlug: this.companyNameSlug } },
              },
              {
                text: 'Feriepengeliste',
                to: { name: 'HolidayPay', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.SALARY],
              },
              {
                text: 'Hovedbok',
                to: { name: 'GeneralLedgerIndex', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Mva. spesifikasjon',
                to: { name: 'VatSpecification', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Reskontro',
                to: { name: 'SubAccounts', params: { companyNameSlug: this.companyNameSlug, type: 'supplier' } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Resultatrapport',
                to: { name: 'IncomeStatementIndex', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Saldobalanse',
                to: { name: 'TrialBalanceIndex', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Sammenstillingsrapport',
                to: { name: 'CompilationReport' },
                company: true,
                modules: [SubscriptionModule.SALARY],
              },
            ],
          },
          {
            text: 'Innstillinger',
            icon: 'mdi-cog',
            color: 'grey',
            items: [
              {
                text: 'Avdelinger',
                to: { name: 'Departments', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.INVOICE],
              },
              {
                text: 'Bankavstemninginnst',
                to: { name: 'BankReconciliationSettings', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Betalingsinnstillinger',
                to: { name: 'PaymentSettingsIndex', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.INVOICE],
              },
              {
                text: 'Integrasjoner',
                to: { name: 'IntegrationsSettings', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Kontoplan',
                to: { name: 'AccountingAccountsIndex', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Lønnsinnstillinger',
                to: { name: 'SalarySettings', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.SALARY],
              },
              {
                text: 'Låste perioder',
                to: { name: 'LockPeriodSettings', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              { text: 'Min profil', to: '/profile' },
              {
                text: 'Moduler',
                to: { name: 'Modules', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.INVOICE],
              },
              {
                text: 'Prosjekter',
                to: { name: 'Projects', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.INVOICE],
              },
              {
                text: 'Selskapsinnstillinger',
                to: { name: 'Settings', params: { companyNameSlug: this.companyNameSlug } },
                modules: [SubscriptionModule.INVOICE],
              },
              {
                text: 'Timeføringsinnstillinger',
                to: { name: 'TimesheetsSettings', params: { companyNameSlug: this.companyNameSlug } },
                modules: [SubscriptionModule.SALARY],
              },
              {
                text: 'Åpningsbalanse',
                to: { name: 'OpeningBalance', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
            ],
          },
        ];
        if (this.user?.accountingFirmId) {
          menu.push({
            text: 'Mitt regnskapsbyrå',
            icon: 'mdi-domain-plus',
            color: 'grey',
            to: { name: 'AccountingFirmCustomers' },
          });
        }
        if (this.user?.systemRole !== 'COMMON') {
          menu.push({
            text: 'Systima CP',
            icon: 'mdi-cog',
            color: 'grey',
            to: '/cp',
          });
        }
        if (this.companyIsOnboarded) {
          menu.splice(2, 0, {
            text: 'Betalinger',
            icon: 'mdi-cash-multiple',
            to: { name: 'PaymentOverviewIndex', params: { companyNameSlug: this.companyNameSlug } },
            company: true,
            exact: true,
            modules: [SubscriptionModule.ACCOUNTING],
          });
        }
        return menu;
      } else {
        const menu: MenuItem[] = [
          {
            text: 'Oversikt',
            icon: 'mdi-home',
            to: { name: 'Dashboard', params: { companyNameSlug: this.companyNameSlug } },
            company: true,
            modules: [SubscriptionModule.INVOICE],
          },
          {
            text: 'Faktura',
            icon: 'mdi-text-box-plus-outline',
            color: 'success',
            company: true,
            to: { name: 'Invoices', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.INVOICE],
          },
          {
            text: 'Filmottak',
            icon: 'mdi-book-plus-outline',
            color: 'success',
            company: true,
            to: { name: 'FileInbox', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.ACCOUNTING],
          },
          {
            text: 'Kontakter',
            icon: 'mdi-account-multiple',
            color: 'amber',
            to: { name: 'Contacts', params: { companyNameSlug: this.companyNameSlug } },
            company: true,
            modules: [SubscriptionModule.INVOICE],
          },
          {
            text: 'Produkter',
            icon: 'mdi-cube',
            color: 'purple',
            company: true,
            to: { name: 'Products', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.INVOICE],
          },
          {
            text: 'Ansatte',
            icon: 'mdi-badge-account',
            color: 'amber',
            company: true,
            to: { name: 'Employees', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.SALARY],
          },
          {
            text: 'Timeføring',
            icon: 'mdi-clock-outline',
            company: true,
            to: { name: 'Timesheet', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.SALARY, SubscriptionModule.TIMESHEET],
          },
          {
            text: 'Lønn',
            icon: 'mdi-credit-card-outline',
            color: 'amber',
            company: true,
            to: { name: 'Salary', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.SALARY],
          },
          {
            text: 'Rapporter',
            icon: 'mdi-chart-scatter-plot-hexbin',
            color: 'success',
            company: true,
            modules: [SubscriptionModule.ACCOUNTING],
            items: [
              {
                text: 'Resultatrapport',
                to: { name: 'IncomeStatementIndex', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
              {
                text: 'Balanserapport',
                to: { name: 'BalanceSheetIndex', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
              },
            ],
          },
          {
            text: 'Innstillinger',
            icon: 'mdi-cog',
            color: 'grey',
            items: [
              {
                text: 'Avdelinger',
                to: { name: 'Departments', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
              },
              { text: 'Min profil', to: { name: 'Profile' } },
              {
                text: 'Moduler',
                to: { name: 'Modules', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
              },
              {
                text: 'Prosjekter',
                to: { name: 'Projects', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
              },
              {
                text: 'Selskapsinnstillinger',
                to: { name: 'Settings', params: { companyNameSlug: this.companyNameSlug } },
                modules: [SubscriptionModule.INVOICE],
              },
            ],
          },
        ];
        if (this.user?.accountingFirmId) {
          menu.push({
            text: 'Mitt regnskapsbyrå',
            icon: 'mdi-domain-plus',
            color: 'grey',
            to: { name: 'AccountingFirmCustomers' },
          });
        }
        if (this.user?.systemRole !== 'COMMON') {
          menu.push({
            text: 'Systima CP',
            icon: 'mdi-cog',
            color: 'grey',
            to: '/cp',
          });
        }
        if (this.companyIsOnboarded) {
          menu.splice(3, 0, {
            text: 'Betalinger',
            icon: 'mdi-cash-multiple',
            to: { name: 'PaymentOverviewIndex', params: { companyNameSlug: this.companyNameSlug } },
            company: true,
            exact: true,
            modules: [SubscriptionModule.ACCOUNTING],
          });
        }
        return menu;
      }
    },
    itemsFiltered(): MenuItem[] {
      const modules: any[] = this.subscription?.subscriptionPlan.modules || [];

      const filterFn = (item: MenuItem) => {
        const hasCompanies = !item.company || this.companies.length;
        const hasModule = !item.modules || modules.some((module) => item.modules?.includes(module));
        const hasRole = !item.systemRole || this.user?.systemRole === item.systemRole;

        if (item.items) {
          item.items = item.items.filter(filterFn);
        }

        return hasCompanies && hasModule && hasRole;
      };

      const items = JSON.parse(JSON.stringify(this.items));

      return items.filter(filterFn);
    },
  },
  async mounted() {
    this.loading = true;
    try {
      await this.fetchSubscription({ companyId: this.selectedCompany.id });
    } catch (e: any) {
      if (e.status === 404 && this.$route?.name !== 'Modules') {
        this.$router.push({ name: 'Modules', params: { companyNameSlug: this.companyNameSlug } });
      }
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchSubscription: 'subscription/fetchSubscription',
    }),
    logoClick() {
      if (this.currentSubscription[this.company.id]) {
        this.$router.push({ name: 'Dashboard', params: { companyNameSlug: this.companyNameSlug } });
      }
    },
  },
});
</script>
