import axios from 'axios';
import { AccountingService } from './accountingService';
import { AccountGroupService } from './accountGroupService';
import { AltinnIntegrationService } from './altinnIntegrationService';
import { AMessageCodeService } from './AMessageCodeService';
import { AttachmentService } from './attachmentService';
import { AuthService } from './authService';
import { BankAccountService } from './bankAccountService';
import { BankIntegrationService } from './bankIntegrationService';
import { CompanyAuditLogService } from './companyAuditLogService';
import { CompanyContactService } from './companyContactService';
import { CompanyLogoService } from './companyLogoService';
import { CompanyModuleService } from './companyModuleService';
import { CompanyOnboardStepService } from './companyOnboardStepService';
import { CompanyProductService } from './companyProductService';
import { CompanyService } from './companyService';
import { CreditNoteAttachmentService } from './creditNoteAttachmentService';
import { CreditNoteService } from './creditNoteService';
import { DashboardService } from './dashboardService';
import { DepartmentService } from './departmentService';
import { downloadReportService } from './downloadReportService';
import { EmployeeService } from './employeeService';
import { FacebookAuthService } from './facebookAuthService';
import { FeedbackService } from './feedbackService';
import { FileService } from './fileService';
import { GoogleAuthService } from './googleAuthService';
import { HealthCheckService } from './healthCheckService';
import { IdPortenService } from './idPortenService';
import { ImportContactsService } from './importContactsService';
import { ImportProductsService } from './importProductsService';
import { IntegrationsService } from './integrationsService';
import { InvoiceAttachmentService } from './invoiceAttachmentService';
import { InvoiceCommentService } from './invoiceCommentService';
import { InvoiceDraftPreviewService } from './invoiceDraftPreviewService';
import { InvoiceDraftService } from './invoiceDraftService';
import { InvoiceSendingService } from './invoiceSendingService';
import { InvoiceService } from './invoiceService';
import { InvoiceRepetitiveService } from './invoiceRepetitiveService';
import { LookupService } from './lookupService';
import { OccupationService } from './occupationService';
import { OpeningBalanceService } from './openingBalanceService';
import { PaymentService } from './paymentService';
import { ProjectService } from './projectService';
import { PayRunService } from './payRunService';
import { ProfileService } from './profileService';
import { SalaryService } from './salaryService';
import { SubAccountsService } from './subAccountsService';
import { SubscriptionService } from './subscriptionService';
import { UserService } from './userService';
import { VATReportService } from './vatReportService';
import { TermSettlementService } from './term-settlement';
import { PensionService } from './pensionService';
import { FileInboxService } from './fileInboxService';
import { UIService } from './uiService';
import { KidService } from './kidService';
import { ControlPanelService } from './controlPanelService';
import { AccountingFirmService } from './accountingFirmService';
import { BookkeepingSuggestionService } from './bookkeepingSuggestionService';
import { CompanyUsersService } from './companyUsersService';
import { TimesheetService } from './timesheetService';
import { I18nService } from '@/services/i18nService';
import { BankReconciliationSettingsService } from './bankReconciliationSettingsService';
import { VoucherService } from './voucherService';
import { VATService } from './vatService';

export const APIController = {
  // Helpers
  isCancel: axios.isCancel,

  // Controllers
  accounting: AccountingService,
  accountGroup: AccountGroupService,
  accountingFirm: AccountingFirmService,
  altinnIntegration: AltinnIntegrationService,
  aMessageCode: AMessageCodeService,
  attachment: AttachmentService,
  auth: AuthService,
  bankAccount: BankAccountService,
  bankIntegration: BankIntegrationService,
  bankReconciliationSettings: BankReconciliationSettingsService,
  bookkeepingSuggestion: BookkeepingSuggestionService,
  companyAuditLog: CompanyAuditLogService,
  companyContact: CompanyContactService,
  companyLogo: CompanyLogoService,
  companyModule: CompanyModuleService,
  companyOnboardStep: CompanyOnboardStepService,
  companyProduct: CompanyProductService,
  company: CompanyService,
  creditNoteAttachment: CreditNoteAttachmentService,
  creditNote: CreditNoteService,
  controlPanel: ControlPanelService,
  dashboard: DashboardService,
  department: DepartmentService,
  downloadReport: downloadReportService,
  employee: EmployeeService,
  facebookAuth: FacebookAuthService,
  feedback: FeedbackService,
  file: FileService,
  fileInbox: FileInboxService,
  googleAuth: GoogleAuthService,
  healthCheck: HealthCheckService,
  idPorten: IdPortenService,
  importContacts: ImportContactsService,
  importProducts: ImportProductsService,
  integrations: IntegrationsService,
  invoiceAttachment: InvoiceAttachmentService,
  invoiceComment: InvoiceCommentService,
  invoiceDraftPreview: InvoiceDraftPreviewService,
  invoiceDraft: InvoiceDraftService,
  invoiceSending: InvoiceSendingService,
  invoice: InvoiceService,
  invoiceRepetitive: InvoiceRepetitiveService,
  pension: PensionService,
  lookup: LookupService,
  occupation: OccupationService,
  openingBalance: OpeningBalanceService,
  payment: PaymentService,
  payRun: PayRunService,
  profile: ProfileService,
  project: ProjectService,
  salary: SalaryService,
  subAccounts: SubAccountsService,
  subscription: SubscriptionService,
  user: UserService,
  vatReport: VATReportService,
  termSettlement: TermSettlementService,
  ui: UIService,
  kid: KidService,
  companyUsers: CompanyUsersService,
  timesheet: TimesheetService,
  i18n: I18nService,
  voucher: VoucherService,
  vat: VATService,
};
