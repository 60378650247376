import { UserService } from '@/services/userService';

const state = {};
const actions = {
  async getUser({ commit }) {
    const userResponse = await UserService.getUser();
    const mode = userResponse ? userResponse.mode || 'SIMPLE' : 'SIMPLE';
    commit('theme/setAdvancedMode', mode === 'ADVANCED', { root: true });
    commit('set', { currentUser: userResponse }, { root: true });
    return userResponse;
  },
  createUser(context, data) {
    return UserService.createUser(data);
  },
  async updateUser({ commit }, data) {
    const userResponse = await UserService.updateUser(data);
    commit('set', { currentUser: userResponse }, { root: true });
    return userResponse;
  },
  updateUserPassword(context, data) {
    return UserService.updateUserPassword(data);
  },
  sendConfirmation() {
    return UserService.sendConfirmation();
  },
  getNotifications(context, { ignoreCache }) {
    return UserService.getNotifications(ignoreCache);
  },
  markNotificationAsViewed(context, { notificationId }) {
    return UserService.updateNotification(notificationId, { viewed: true });
  },
  resetUnreadNotification() {
    return UserService.resetUnreadNotification();
  },
};
const mutations = {};
const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
