<template>
  <div>
    <v-card-title>Slett fil</v-card-title>
    <v-card-text>
      <p>Er du sikker på at du ønsker å slette denne filen?</p>
      <div class="d-flex justify-end mt-4">
        <v-btn class="mr-2" text color="secondary" @click="$emit('cancel')">Avbryt</v-btn>
        <v-btn :loading="isLoading" :disabled="isLoading" depressed color="error" @click="deleteAttachment">
          Slett<v-icon right>mdi-delete</v-icon>
        </v-btn>
      </div>
    </v-card-text>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data: () => ({
    isLoading: false,
  }),
  methods: {
    async deleteAttachment() {
      this.isLoading = true;

      try {
        await this.$api.openingBalance.deleteAttachment(this.companyId);

        this.$emit('deleted');
      } catch (e) {
        this.$sentry.captureException(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>
