import type { FileResponse } from '@/types/schema';
import api from '@/utils/api';

export const AttachmentService = {
  getAttachment(companyId: string, attachmentId: string): Promise<FileResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/attachments/${attachmentId}`,
      ignoreCache: true,
    });
  },
  downloadAttachment(companyId: string, attachmentId: string, raw = false): Promise<ArrayBuffer> {
    return api({
      method: 'get',
      url: `companies/${companyId}/attachments/${attachmentId}/download`,
      responseType: 'arraybuffer',
      ignoreCache: true,
      raw,
    });
  },
};
