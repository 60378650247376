import type { OpeningBalanceImportType } from '@/types';
import type {
  FileResponse,
  ImportEmployeeExpensesResponse,
  ImportEmployeeRemunerationsResponse,
  ImportInvoiceResponse,
  ImportPurchaseResponse,
  ImportTrialBalanceResponse,
  OpeningBalanceResponse,
  OpeningBalanceSaveBody,
} from '@/types/schema';
import api from '@/utils/api';

type ImportResponse =
  | ImportInvoiceResponse
  | ImportPurchaseResponse
  | ImportTrialBalanceResponse
  | ImportEmployeeExpensesResponse
  | ImportEmployeeRemunerationsResponse;

export const OpeningBalanceService = {
  import(
    companyId: string,
    type: OpeningBalanceImportType,
    file: any,
    data?: Record<string, string>,
  ): Promise<ImportResponse> {
    const formData = new FormData();

    formData.append('file', file);

    if (data) {
      formData.append('refinement', 'true');

      for (const [key, val] of Object.entries(data)) {
        formData.append(`${key}ColumnName`, val);
      }
    }

    return api({
      method: 'post',
      url: `companies/${companyId}/opening_balance/import/${type}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      silenceErrorAlert: true,
    });
  },
  downloadTemplate(companyId: string, type: OpeningBalanceImportType): Promise<any> {
    return api({
      method: 'get',
      url: `companies/${companyId}/opening_balance/import/${type}/template`,
      responseType: 'arraybuffer',
    });
  },
  createOpeningBalance(companyId: string, openingBalance: OpeningBalanceSaveBody): Promise<OpeningBalanceResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/opening_balance`,
      data: openingBalance,
    });
  },
  getOpeningBalance(companyId: string): Promise<OpeningBalanceResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/opening_balance`,
    });
  },
  uploadAttachment(companyId: string, fileId: string): Promise<void> {
    return api({
      method: 'post',
      url: `companies/${companyId}/opening-balance/attachments`,
      data: { fileId },
    });
  },
  getAttachment(companyId: string): Promise<FileResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/opening-balance/attachments`,
      silenceErrorAlert: true,
    });
  },
  deleteAttachment(companyId: string): Promise<void> {
    return api({
      method: 'delete',
      url: `companies/${companyId}/opening-balance/attachments`,
    });
  },
};
