<template>
  <v-navigation-drawer
    id="appNavDrawer"
    ref="navDrawer"
    :value="isNavDrawerVisible"
    :dark="false"
    :disable-resize-watcher="false"
    :expand-on-hover.sync="isNavDrawerMinimized"
    :mobile-breakpoint="960"
    app
    floating
    color="white"
    height="100%"
    class="shadow-sm rounded-tr-lg rounded-br-lg"
    @transitionend="transitionend"
  >
    <v-list dense nav>
      <v-list-item style="min-height: 60px" class="px-0 pt-2">
        <v-list-item-avatar class="mr-0" style="border-radius: 0">
          <img style="height: 40px; width: 32px" src="/assets/systima.png" @click="logoClick" />
        </v-list-item-avatar>
        <v-list-item-title class="text-18 text-uppercase text-default">
          <img style="position: relative; left: -36px" src="/assets/logo.png" @click="logoClick" />
        </v-list-item-title>
        <v-scroll-x-transition>
          <v-btn icon @click.stop="toggleSidebar">
            <v-tooltip v-if="!isNavDrawerMinimized" right color="primary">
              <template #activator="{ on, attrs }">
                <v-icon dark v-bind="attrs" color v-on="on">mdi-circle-slice-8</v-icon>
              </template>
              <span>Unpin</span>
            </v-tooltip>
            <v-tooltip v-if="isNavDrawerMinimized" right color="primary">
              <template #activator="{ on, attrs }">
                <v-icon dark v-bind="attrs" color v-on="on">mdi-circle-outline</v-icon>
              </template>
              <span>Pin</span>
            </v-tooltip>
          </v-btn>
        </v-scroll-x-transition>
      </v-list-item>
    </v-list>

    <v-list class="py-0">
      <template v-for="(item, index) in items">
        <ListGroup v-if="item.items && item.items.length" :key="`group-${index}`" :item="item" />
        <ListItem v-else :key="`item-${index}`" :item="item" />
      </template>
    </v-list>

    <template v-if="!$store.state.theme.isNavDrawerMinimized || showUserInfo" #append>
      <div class="px-3 py-5 text-no-wrap">
        <div class="text-16 mb-1">{{ fullName }}</div>
        <v-btn to="/profile" small icon class="mr-2">
          <v-icon small>mdi-cog</v-icon>
        </v-btn>
        <v-btn to="/logout" small icon>
          <v-icon small>mdi-logout-variant</v-icon>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import ListGroup from './ListGroup.vue';
import ListItem from './ListItem.vue';

export default {
  components: {
    ListGroup,
    ListItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    logoClick: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      showUserInfo: false,
    };
  },
  computed: {
    ...mapGetters({
      isNavDrawerMinimized: 'theme/getNavDrawerMinimized',
      isNavDrawerVisible: 'theme/getNavDrawerVisible',
    }),
    user() {
      return this.$store.getters.getUser || {};
    },
    fullName() {
      return `${this.user.firstName} ${this.user.lastName}`;
    },
  },
  methods: {
    toggleSidebar() {
      this.$store.dispatch('theme/toggleNavDrawerMinimized');
    },
    transitionend() {
      document.getElementById('appNavDrawer').offsetWidth > 56
        ? (this.showUserInfo = true)
        : (this.showUserInfo = false);
    },
  },
};
</script>

<style lang="scss">
.ps-container {
  position: inherit !important;
}
.nav-drawer--minimized {
  .v-toolbar {
    left: 56px !important;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .v-main {
    padding: 110px 0px 12px 56px !important;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    padding-bottom: 0px !important;
  }
  &.nav-drawer--visible {
    .v-toolbar {
      left: 56px !important;
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .v-main {
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      padding-bottom: 0px !important;
    }
  }
}
</style>
