import * as Sentry from '@sentry/vue';
import type { BaseErrorResponse, InternationalizationResponse } from '@/types/schema';
import constants from '@/utils/constants';
import { getWithTTL } from '@/utils/localStorage';

export const defaultErrorTexts = {
  generic: 'Noe gikk galt. Vennligst last siden på nytt, og gi oss beskjed om feilen oppstår på nytt.',
  server:
    'Det har oppstått en serverfeil. Vi har fått beskjed om dette og kommer til å fikse problemet så fort som mulig. Prøv igjen om litt.',
} as const;

export function getErrorTexts(): InternationalizationResponse & typeof defaultErrorTexts {
  const errorTexts = getWithTTL<InternationalizationResponse>(constants.i18nLocalStorageKey, false) || {};

  return {
    ...errorTexts,
    ...defaultErrorTexts,
  };
}

function replaceMetaValues(message: string, meta: BaseErrorResponse['meta']): string {
  if (meta) {
    Object.entries(meta).forEach(([key, value]) => {
      message = message.replace(`{${key}}`, value);
    });
  }

  return message;
}

function resolveErrorText(error: Pick<BaseErrorResponse, 'code' | 'message' | 'meta'>): string {
  const errorTexts = getErrorTexts();

  if (error.code && errorTexts[error.code]) {
    const message = errorTexts[error.code] as string;

    return replaceMetaValues(message, error.meta);
  }

  Sentry.captureMessage(`Error code not found in dictionary: ${error.code}`);

  return error.message || defaultErrorTexts.generic;
}

export function getTextByError(error: BaseErrorResponse): string[] {
  if (error.errors?.length) {
    const resolvedTexts = error.errors.map(resolveErrorText);
    const unique = new Set(resolvedTexts);

    // in case none of the errors in the array are valid (unknown code or missing message)
    // fallback to the root error object
    if (unique.size === 1 && unique.has(defaultErrorTexts.generic)) {
      return [resolveErrorText(error)];
    }

    return Array.from(unique);
  }

  return [resolveErrorText(error)];
}
