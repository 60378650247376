import { AccountingService } from '@/services/accountingService';

const state = {};
const actions = {
  getPaychecks(_context, { companyId, id }) {
    return AccountingService.getPaychecks(companyId, id);
  },
};
const mutations = {};
const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
