import { FileService } from '@/services/fileService';

const defaultState = {
  file: null, // File object
  fileId: null,
  attachmentMetaData: {
    id: null,
    name: '',
    mediaType: '',
    type: '',
    createdAt: null,
    downloadUrl: '',
  },
  isUploading: false,
  rawData: null,
};
const state = () => {
  return JSON.parse(JSON.stringify(defaultState));
};
const actions = {
  async uploadAttachment({ commit, dispatch }, { companyId, file, entity, skipReset, skipDownload }) {
    commit('setUploading', true);

    try {
      const uploadedFileMetaData = await FileService.uploadFile(companyId, file, entity);

      if (!skipDownload) {
        commit('updateFileData', uploadedFileMetaData);
        if (uploadedFileMetaData.mediaType === 'application/pdf') {
          dispatch('getRawData', { fileId: uploadedFileMetaData.id, companyId }); /* lazy loading of the rawData */
        }
        !skipReset && commit('resetToDefault');
      }

      return uploadedFileMetaData;
    } finally {
      commit('setUploading', false);
    }
  },
  async getRawData({ commit }, { fileId, companyId }) {
    const rawData = await FileService.downloadFile(companyId, fileId);
    commit('updateRawData', rawData);
    return rawData;
  },
  async deleteAttachment({ commit }) {
    commit('resetToDefault');
  },
  resetData({ commit }) {
    commit('resetToDefault');
  },
};
const mutations = {
  setUploading(state, uploading) {
    state.isUploading = uploading;
  },
  setFile(state, file) {
    state.file = file;
  },
  updateFileData(state, metaData) {
    state.fileId = metaData.id;
    state.attachmentMetaData = metaData;
  },
  updateRawData(state, rawData) {
    state.rawData = rawData;
  },
  resetToDefault(state) {
    const keys = Object.keys(state);
    let i = 0;
    const length = keys.length;
    for (; i < length; i++) {
      const key = keys[i];
      if (typeof defaultState[key] === 'undefined') {
        state[key] = undefined;
      } else {
        state[key] = defaultState[key];
      }
    }
  },
};
const getters = {
  file(state) {
    return state.file;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
