import type {
  ManualTransactionCreateBody,
  ManualTransactionJournalEntryRowCreateBody,
  SystimaCustomerInvitationBody,
} from './schema';
import type { DeepPartial } from './util';

export enum LAYOUT {
  unauthorized = 'unauthorized',
  companyIndependent = 'companyIndependent',
  companyDependent = 'companyDependent',
  comingSoon = 'comingSoon',
}

export type CustomerType = {
  text: string;
  value: SystimaCustomerInvitationBody['customerType'];
};

export type Entity = {
  entityType: 'INCOME' | 'MANUAL' | 'PURCHASE' | 'BANK_RECONCILIATION';
  entityId: string;
};

export type EntitySuggestionType = Exclude<Entity['entityType'], 'MANUAL'>;

export enum SubscriptionModule {
  INVOICE = 'invoice',
  ACCOUNTING = 'accounting',
  SALARY = 'salary',
  TIMESHEET = 'timesheet',
}

export type ManualTransaction = Partial<Omit<ManualTransactionCreateBody, 'entries'>>;
export type ManualTransactionEntry = DeepPartial<
  ManualTransactionJournalEntryRowCreateBody & { id?: string; date?: string }
>;

export type OpeningBalanceImportType = 'invoices' | 'purchases' | 'employee_remunerations' | 'trial_balance';

export type PaymentEntityType = 'VAT_REPORT' | 'INVOICE' | 'PURCHASE';

export type DownloadOptionConfig = boolean | { title: string };

export type TableRowAction<T = any> = {
  text: string;
  handler: (row: T) => void;
  disabled?: (row: T) => boolean;
  loading?: (row: T) => boolean;
  disallowed?: ((row: T) => boolean) | boolean;
};

export type PaymentSourceFilter =
  | 'isForIncoming'
  | 'isForOutgoing'
  | 'isForSalary'
  | 'isForTaxWithholding'
  | 'isForInsuranceContributions'
  | 'isForVAT';

export type KeyboardNavigationEvent = KeyboardEvent & { skipKeyboardNavigation: boolean };
