import { AttachmentService } from '@/services/attachmentService';
import { FileService } from '@/services/fileService';

const getDefaultState = () => ({
  attachmentMetaData: {
    createdAt: '',
    id: null,
    mediaType: null,
    name: '',
    description: null,
  },
});

const state = getDefaultState();

const actions = {
  downloadAttachment(_context, { companyId, attachmentId }) {
    return AttachmentService.downloadAttachment(companyId, attachmentId);
  },
  async getAttachment({ commit }, { companyId, attachmentId }) {
    const attachment = await AttachmentService.getAttachment(companyId, attachmentId);
    commit('setAttachmentData', attachment);
    return attachment;
  },
  downloadFile(_context, { companyId, fileId }) {
    return FileService.downloadFile(companyId, fileId);
  },
  resetAttachmentMetaData({ commit }) {
    commit('resetAttachmentMetaData');
  },
  resetState({ commit }) {
    commit('RESET_STATE');
  },
};

const mutations = {
  setAttachmentData(state, attachmentMetaData) {
    state.attachmentMetaData = attachmentMetaData;
  },
  resetAttachmentMetaData(state) {
    state.attachmentMetaData = {};
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
