<template>
  <div v-if="pageTitle" class="d-flex align-center justify-center pl-7">
    <v-select
      v-if="allowChangeUrl"
      ref="select"
      :items="changeUrlOptions"
      :value="currentRouteName"
      hide-details
      class="page-title-select font-weight-medium"
      @change="navigateTo($event)"
    />
    <span v-else class="page-title">{{ pageTitle }}</span>
    <HelpButton :title="pageTitle" :class="{ 'mt-0': allowChangeUrl }">
      <template #content>
        <div v-if="pageDescription" v-html="pageDescription" />
      </template>
    </HelpButton>
  </div>
</template>
<script lang="ts">
import HelpButton from '@/components/HelpButton.vue';
import { defineComponent } from 'vue';

type ChangeUrlOption = {
  text: string;
  value: string;
};

type PageTitleData = {
  changeUrlOptions: ChangeUrlOption[];
  allowChangeUrlOptions: string[];
};

export default defineComponent({
  components: {
    HelpButton,
  },
  data: (): PageTitleData => ({
    changeUrlOptions: [
      {
        text: 'Bokføring kjøp',
        value: 'PurchaseCreate',
      },
      {
        text: 'Bokføring fri',
        value: 'AccountingManualTransactions',
      },
      {
        text: 'Bokføring salg',
        value: 'SaleCreate',
      },
    ],
    allowChangeUrlOptions: ['PurchaseBulkCreate', 'PurchaseCreate', 'SaleCreate', 'AccountingManualTransactions'],
  }),
  computed: {
    pageTitle(): string | undefined {
      if (this.$route.meta?.pageTitleFromStore && this.$store.getters['pageMeta/getPageTitle']) {
        return this.$store.getters['pageMeta/getPageTitle'];
      }

      const { meta } = this.$route;

      return typeof meta?.pageTitle === 'function' ? meta?.pageTitle(this.$route) : meta?.pageTitle;
    },
    pageDescription(): string | undefined {
      if (this.$route.meta?.pageDescriptionFromStore && this.$store.getters['pageMeta/getPageDescription']) {
        return this.$store.getters['pageMeta/getPageDescription'];
      }

      return this.$route.meta?.pageDescription;
    },
    allowChangeUrl(): boolean {
      const {
        name,
        params: { purchaseId, saleId, manualTransactionId },
      } = this.$route;

      return this.allowChangeUrlOptions.includes(name as string) && !(purchaseId || saleId || manualTransactionId);
    },
    currentRouteName(): string {
      let routeName = this.$route.name as string;

      if (routeName === 'PurchaseBulkCreate') {
        routeName = 'PurchaseCreate';
      }

      return routeName;
    },
    selectRef() {
      return this.$refs.select as any;
    },
  },
  methods: {
    async navigateTo(routeName: string) {
      try {
        await this.$router.push({
          name: routeName,
          params: this.$route.params,
          query: this.$route.query,
        });
      } catch (e) {
        this.selectRef.setValue(this.currentRouteName);
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.page-title {
  color: #595959;
  font-size: 0.9rem;
  font-weight: 500;
}
.page-title-select {
  width: 10rem;
}
</style>
