import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import * as Sentry from '@sentry/vue';
import constants from './utils/constants';
import UnauthorizedLayout from '@/layouts/UnauthorizedLayout.vue';
import CompanyIndependentLayout from '@/layouts/CompanyIndependentLayout.vue';
import CompanyDependentLayout from '@/layouts/CompanyDependentLayout.vue';
import ComingSoonLayout from '@/layouts/ComingSoonLayout.vue';
import '@/assets/styles/app.scss';
import '@/assets/scss/octavia-design-system/octavia-design-system.scss';
import KBNavDirective from '@/directives/kb-nav.directive';
import eventBus from './plugins/eventBus';
import vuetify from './plugins/vuetify';
import './plugins/webfontloader';
import './plugins/gtm';
import './plugins/sentry';
import { APIController } from './services';
import { LAYOUT } from './types';
import { getWithTTL, saveWithTTL } from '@/utils/localStorage';
import type { InternationalizationResponse } from '@/types/schema';

Vue.config.productionTip = false;

Vue.prototype.$api = APIController;
Vue.prototype.$eventBus = eventBus;
Vue.prototype.$sentry = Sentry;

Vue.component(LAYOUT.unauthorized, UnauthorizedLayout);
Vue.component(LAYOUT.companyIndependent, CompanyIndependentLayout);
Vue.component(LAYOUT.companyDependent, CompanyDependentLayout);
Vue.component(LAYOUT.comingSoon, ComingSoonLayout);

Vue.mixin({
  computed: {
    companyId() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.$store.getters.selectedCompany.id;
    },
  },
});

Vue.directive('kb-nav', KBNavDirective);

const app = new Vue({
  router,
  store,
  vuetify,
  created() {
    this.$router.onReady(async () => {
      const cachedTranslations = getWithTTL<InternationalizationResponse>(constants.i18nLocalStorageKey);
      if (!cachedTranslations) {
        const translations = await this.$api.i18n.getTranslations();
        saveWithTTL(translations, constants.i18nLocalStorageKey, constants.i18nLocalStorageTTL);
      }

      this.$mount('#app');
    });
  },
  render: (h) => h(App),
});

window.app = app;
