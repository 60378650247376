import api from '@/utils/api';
import type { Entity } from '@/types';
import type { FileResponse, FileSuggestionResponse } from '@/types/schema';

export const AIFileSuggestionAbortKey = 'AIFileSuggestion';

export const FileService = {
  uploadFile(companyId: string, file: File, entity?: Entity): Promise<FileResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/files`,
      file,
      entity,
    });
  },
  deleteFile(
    companyId: string,
    fileId: string,
    params?: {
      entityType: Entity['entityType'];
      entityId: Entity['entityId'];
      moveToInbox: boolean;
    },
  ): Promise<void> {
    return api({
      method: 'delete',
      url: `companies/${companyId}/files/${fileId}`,
      params,
    });
  },
  getFile(companyId: string, fileId: string): Promise<FileResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/files/${fileId}`,
      ignoreCache: true,
    });
  },
  downloadFile(companyId: string, fileId: string): Promise<ArrayBuffer> {
    return api({
      method: 'get',
      url: `companies/${companyId}/files/${fileId}/download`,
      responseType: 'arraybuffer',
      ignoreCache: true,
    });
  },
  getFileSuggestion(companyId: string, fileId: string): Promise<FileSuggestionResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/files/${fileId}/suggestions`,
      abortKey: AIFileSuggestionAbortKey,
    });
  },
};
