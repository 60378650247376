import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import config from '@/utils/config';

Sentry.init({
  Vue,
  dsn: config.sentryDsn,
  environment: config.sentryEnv,
  integrations: [Sentry.extraErrorDataIntegration(), Sentry.httpClientIntegration(), Sentry.replayIntegration()],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  ignoreErrors: [
    'NavigationDuplicated',
    'Failed to fetch dynamically imported module',
    'Unable to preload CSS',
    'Redirected when going from',
    'Navigation cancelled from',
  ],
});
