<template>
  <Drawer :items="items" :logo-click="logoClick" />
</template>

<script>
import Drawer from '@/components/drawer/Drawer.vue';

export default {
  components: { Drawer },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    isCompanySelector() {
      return this.$route.name === 'SelectCompany';
    },
    items() {
      return [
        ...(!this.isCompanySelector
          ? [
              {
                text: 'Tilbake til selskaper',
                icon: 'mdi-arrow-left',
                color: 'grey',
                to: { name: 'SelectCompany' },
                exact: true,
              },
            ]
          : []),
        {
          text: 'Innstillinger',
          icon: 'mdi-cog',
          color: 'grey',
          items: [{ text: 'Min profil', to: '/profile', icon: 'mdi-circle-medium' }],
        },
        ...(this.user?.accountingFirmId
          ? [
              {
                text: 'Mitt regnskapsbyrå',
                icon: 'mdi-domain-plus',
                color: 'grey',
                to: { name: 'AccountingFirmCustomers' },
              },
            ]
          : []),
        ...(this.user?.systemRole !== 'COMMON'
          ? [
              {
                text: 'Systima CP',
                icon: 'mdi-cog',
                color: 'grey',
                to: '/cp',
              },
            ]
          : []),
      ];
    },
  },
  methods: {
    logoClick() {
      this.$router.push({ name: 'SelectCompany', to: '/' });
    },
  },
};
</script>
