import Vue from 'vue';
import { SubscriptionService } from '@/services/subscriptionService';

const getDefaultState = () => ({
  subscriptionPromise: {} /* {companyId: Promise<{currentSubscription}>} */,
  currentSubscription: {} /* {companyId: {currentSubscription} */,
});

const state = getDefaultState();

const actions = {
  async fetchSubscription({ state, commit }, { companyId }) {
    if (!state.subscriptionPromise[companyId]) {
      const promise = SubscriptionService.fetchCurrentSubscription(companyId);

      commit('setSubscriptionPromise', { companyId, promise });

      promise.then((response) => {
        commit('setCurrentSubscription', { companyId, data: response });
        return response;
      });
    }
    return state.currentSubscription[companyId] || state.subscriptionPromise[companyId];
  },
  async changeSubscription({ commit }, { companyId, payload }) {
    const currentSubscription = await SubscriptionService.changeSubscription(companyId, payload);
    commit('setCurrentSubscription', { companyId, data: currentSubscription });
    return currentSubscription;
  },
  resetState({ commit }) {
    commit('RESET_STATE');
  },
};

const mutations = {
  setCurrentSubscription(state, { companyId, data }) {
    Vue.set(state.currentSubscription, companyId, data);
  },
  setSubscriptionPromise(state, { companyId, promise }) {
    state.subscriptionPromise[companyId] = promise;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const getters = {
  getSubscriptionByCode(state) {
    return (code) => {
      return state.availableSubscriptions.find((item) => {
        return item.code === code;
      });
    };
  },
  getSubscriptionByCompanyId(state) {
    return (companyId) => {
      return state.currentSubscription[companyId];
    };
  },
  getIsTimesheetUser(state) {
    return (companyId) => {
      return !!state.currentSubscription[companyId]?.subscriptionPlan.modules.every((module) => module === 'timesheet');
    };
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
