<template>
  <v-app class="unauthorized-layout">
    <v-main fluid :style="$vuetify.theme.dark ? '' : 'background-color: #f5f5f5'">
      <div id="contentContainer">
        <router-view></router-view>
      </div>
      <ErrorAlert />
      <v-snackbar color="success" :value="!!successMessage" @input="$emit('success:dismiss')">
        <div v-html="successMessage" />
        <template #action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="$emit('success:dismiss')">
            <v-icon dark right> mdi-close-circle </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ErrorAlert from '@/components/ErrorAlert.vue';

export default defineComponent({
  components: { ErrorAlert },
  props: {
    successMessage: {
      type: String,
      default: null,
    },
  },
});
</script>
