export function isPdf(file) {
  return file.type === 'application/pdf';
}

export function isImage(file) {
  return file.type.startsWith('image/');
}

// Returns a truncated filename
// e.g. 'this-is-a-very-long-file-name.pdf' => 'this-is-a-very-lon....pdf'
export function formatFileName(file, maxLength = 20) {
  if (!file?.name) return '';

  const filename = file.name.substr(0, file.name.lastIndexOf('.'));
  const extension = file.name.substr(file.name.lastIndexOf('.') + 1, file.name.length);

  return `${filename.slice(0, maxLength)}${filename.length > maxLength ? '...' : ''}.${extension}`;
}

// Returns a pretty formatted filesize
// e.g. "1.1 MB", "120 KB"
export function formatFileSize(bytes) {
  const units = { tb: 'TB', gb: 'GB', mb: 'MB', kb: 'KB', b: 'b' };
  let selectedSize = 0;
  let selectedUnit = 'b';

  if (bytes > 0) {
    const units = ['tb', 'gb', 'mb', 'kb', 'b'];

    for (let i = 0; i < units.length; i++) {
      const unit = units[i];
      const cutoff = Math.pow(1000, 4 - i) / 10;

      if (bytes >= cutoff) {
        selectedSize = bytes / Math.pow(1000, 4 - i);
        selectedUnit = unit;
        break;
      }
    }

    selectedSize = Math.round(10 * selectedSize) / 10; // Cutting of digits
  }

  return `${selectedSize} ${units[selectedUnit]}`;
}
