<template>
  <div class="comingSoon">
    <div class="comingSoonLogo"><img src="/assets/wrench.svg" /></div>
    <div class="comingSoonTitle">Vi oppgraderer til Systima Regnskap!</div>
    <div>
      Vi har nå nedetid frem til mandag ca klokken 15:00 da vi gjør en stor oppgradering av eksisterende system. Etter
      lansering vil fakturaprogrammet bli oppdatert, samt vi vil lansere modul for regnskap. Vi kommer til å lansere en
      BETA versjon for regnskap som vil være gratis å teste.
    </div>
    <div class="comingSoonContent">
      <div v-for="card in textCards" :key="card.header" class="comingSoonCard">
        <div class="comingSoonCardTitle">{{ card.header }}</div>
        <div>{{ card.body }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      textCards: [
        {
          header: 'Hva skjer når dere har oppgradert?',
          body: 'Når vi har oppgradert vil det skje flere ting. Vi kommer til å få et nytt design, lansere regnskapsmoduler samt lansere flere oppgraderinger av eksisterende fakturamodul. EHF vil bli aktivert igjen. Fra lansering kommer vi også til å ha ukentlige oppdateringer.',
        },
        {
          header: 'Hva skjer med dataen min?',
          body: 'Data du har fra før vil du fortsette å ha når vi har gjennomført oppdateringen. Dersom du ønsker å oppgradere til Regnskap vil alle nødvendige bokføringer også automatisk bli gjennomført. Du skal ikke trenge å gjøre noe manuelt.',
        },
        {
          header: 'Vil Systima koste penger nå?',
          body: 'Etter oppdateringen kommer vi til å ha en ny prisstrategi. Denne vil bli offentlig første gang du logger inn. Du vil da ha mulighet til å velge mellom ulike moduler.',
        },
        {
          header: 'Hva gjør jeg om jeg har mistet data eller opplever problemer?',
          body: 'Dersom du opplever noen problemer send oss en mail til post@systima.no',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.comingSoon {
  width: 100%;
  background: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  gap: 0.5em;
  overflow: auto;

  > div {
    max-width: 65vw;
    min-width: 55em;
    text-align: center;
  }

  .comingSoonLogo {
    height: 10em;
    img {
      height: 100%;
      width: auto;
    }
  }

  .comingSoonTitle {
    font-size: 1.25em;
    font-weight: bold;
  }

  .comingSoonContent {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 1em 0;

    > .comingSoonCard {
      border: 1px solid #000000;
      padding: 1em;

      .comingSoonCardTitle {
        font-weight: bold;
        font-size: 1.25em;
        padding: 0 0 0.5em 0;
      }
    }
  }
}
</style>
