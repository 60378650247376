<template>
  <v-app
    :class="{
      'nav-drawer--minimized': isNavDrawerMinimized,
      'nav-drawer--visible': isNavDrawerVisible,
    }"
  >
    <CompanyIndependentNavigationDrawer />
    <AppBar />
    <v-main :style="'background-color: #f2f3f8; padding-top: 110px;'">
      <v-container id="contentContainer" fluid class="wrapper-container">
        <v-layout v-if="isLoadingCompany" ma-2 align-center justify-center>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-layout>
        <router-view v-else :key="selectedCompany.id" />
      </v-container>
      <ErrorAlert />
      <v-snackbar color="success" :value="!!successMessage" @input="$emit('success:dismiss')">
        <div v-html="successMessage" />
        <template #action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="$emit('success:dismiss')">
            <v-icon dark right> mdi-close-circle </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import CompanyIndependentNavigationDrawer from '@/modules/drawer/CompanyIndependentNavigationDrawer.vue';
import AppBar from '@/components/app-bar/AppBar.vue';
import { defineComponent } from 'vue';
import ErrorAlert from '@/components/ErrorAlert.vue';

export default defineComponent({
  components: { CompanyIndependentNavigationDrawer, AppBar, ErrorAlert },
  props: {
    successMessage: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      isNavDrawerMinimized: 'theme/getNavDrawerMinimized',
      isNavDrawerVisible: 'theme/getNavDrawerVisible',
      selectedCompany: 'selectedCompany',
    }),
    isLoadingCompany() {
      return this.$store.state.company.isLoadingCompany;
    },
  },
});
</script>
