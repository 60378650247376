<template>
  <div class="s-file-preview">
    <!-- pdf -->
    <PDFPreview v-if="isPdf(file)" :raw-data="file" />

    <!-- images -->
    <div v-else-if="isImage(file)" class="s-file-preview__image">
      <img ref="img" :src="createObjectUrl(file)" @load="revokeObjectUrl" />
    </div>

    <!-- other -->
    <div v-else class="pa-5 text-center ma-auto">
      <v-icon size="5rem" class="mb-5">mdi-file-document</v-icon>
      <h4 class="font-weight-medium" style="max-width: 450px">Forhåndsvisning støttes ikke for opplastet filtype</h4>
    </div>
  </div>
</template>

<script>
import PDFPreview from '@/components/PDFPreview.vue';
import { isImage, isPdf, formatFileSize } from './utils';

export default {
  components: {
    PDFPreview,
  },
  props: {
    file: {
      type: File,
      required: true,
    },
  },
  methods: {
    isPdf,
    isImage,
    formatFileSize,
    createObjectUrl(file) {
      return URL.createObjectURL(file);
    },
    revokeObjectUrl() {
      this.$refs.img?.src && URL.revokeObjectURL(this.$refs.img.src);
    },
  },
};
</script>

<style lang="scss" scoped>
.s-file-preview {
  min-height: 250px;
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;

  &__image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      object-fit: cover;
      margin: auto;
    }
  }
}
</style>
